import * as React from "react";
import styled, { css } from "styled-components";
import piranha from "./../images/piranha.png";
import yes from "./../images/yes.png";

import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
    background: #0e0e0f;
    background: #214fc6;
    color: #ffffff;
    font-family: "Avenir", sans-serif;  
  }

  body {
    font-size: 1rem;
    padding: 0;
    margin: 0;
    cursor: default;
    position: relative;
    width: 100vw;
    height: 100vh;
    text-align: center;
  }

  h1 {
    font-size: 72px;
  }
`;

const Page = styled.main`
  max-width: 600px;
  padding: 15px;
  margin: 80px auto;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

const Piranha = styled.img`
  max-width: 40%;
  width: 320px;
  right: 0;
  bottom: 0;
  position: absolute;
`;

const Yes = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 60%;
`;

// markup
const IndexPage = () => {
  return (
    <>
      <GlobalStyles />
      <Page>
        <title>Home Page</title>
        <h1>Coming soon</h1>
        <h2>feenachisholm.com</h2>
        <Yes src={yes} />
        <Piranha src={piranha} />
      </Page>
    </>
  );
};

export default IndexPage;
